export type ComponentGroup<T extends CF2Component> = T & {
  on: (eventName: string, eventHandler: CallableFunction) => void
}

interface CF2ComponentConstructor<T extends CF2Component> {
  new (...args: string[]): T
  readonly prototype: T
}

type GetHandlers<T> = {
  [K in keyof T as K extends `on${string}` ? (T[K] extends CallableFunction ? K : never) : never]?: T[K]
}

const componentByName: Record<string, CF2ComponentConstructor<any>> = {}
const componentNameByClass: Record<string, string> = {}

export function registerComponent(name: string, klass: CF2ComponentConstructor<any>): void {
  componentByName[name] = klass
  componentNameByClass[klass.name] = name
}

export function createComponentGroup<T extends CF2Component>(
  klass: CF2ComponentConstructor<T>,
  components: T[]
): ComponentGroup<T> {
  const groupObject = {} as ComponentGroup<T>
  groupObject.on = (eventName: string, eventHandler: CallableFunction) => {
    components.forEach((c) => {
      c.on(eventName, eventHandler)
    })
  }
  for (const propertyName of Object.getOwnPropertyNames(klass.prototype)) {
    if (!['constructor'].includes(propertyName)) {
      groupObject[propertyName] = (...args) => {
        components.forEach((c) => {
          klass.prototype[propertyName].apply(c, args)
        })
      }
    }
  }
  return groupObject
}

export abstract class CF2Component {
  id: string
  subscribers: Record<string, CallableFunction[]>
  params: Record<string, any>
  mutationOberver: MutationObserver

  constructor(public element: CF2Element) {
    this.subscribers = {}
    this.id = Array.from(this.element.classList).find((c) => c.startsWith('id'))

    for (const propertyName of Object.getOwnPropertyNames(this.constructor.prototype)) {
      if (propertyName.startsWith('on')) {
        if (typeof this.constructor.prototype[propertyName] === 'function') {
          this.subscribers[propertyName] = []
          this[propertyName] = (...args: any[]) => {
            return this.subscribers[propertyName].map((eventHandler) => (eventHandler as any).apply(this, args))
          }
        } else {
          console.error(
            // eslint-disable-next-line max-len
            `[Component=${this.constructor.name}] Method ${propertyName} is reserved for event listeners and should be defined as a empty function => on<EventName>() {}\n  Got:\n`,
            this.constructor.prototype[propertyName].toString()
          )
        }
      }
    }

    for (const dataName in this.element.dataset) {
      if (!dataName.startsWith('param')) {
        this[dataName] = this.element.dataset[dataName]
      }
    }

    const stateNodeData = CF2Component.getStateNodeData(this.element)
    if (stateNodeData) {
      Object.assign(this, stateNodeData)
    }

    if (this.afterMount) {
      document.addEventListener('CF2:HydrateTreeInitialized', () => {
        this.afterMount()
      })
    }
  }

  static getStateNodeData(element: CF2Element): Record<string, any> {
    const id = element.getAttribute('data-state-node-script-id')
    const stateNode = id && (document.getElementById(id) as HTMLElement)
    if (id && stateNode) {
      return JSON.parse(stateNode.textContent)
    }
  }

  // eslint-disable-next-line
  mount(element?: CF2Element): void {}

  // eslint-disable-next-line
  render(): void {}

  // eslint-disable-next-line
  initialize(): void {}

  // initialize and mount were not enough for cases where there are a dependency between
  // two components that are not in the same subtree.
  // afterMount fires after all components have been mounted.
  abstract afterMount(): void

  // TODO: Refactor getComponent and getComponents to receive a class instead of a string
  getComponent<T extends CF2Component>(klass: CF2ComponentConstructor<T> | string): T {
    const componentName = typeof klass === 'string' ? klass : componentNameByClass[klass.name]
    return this.element.querySelector<CF2Element>(`[data-page-element="${componentName}"]`)?.cf2_instance as T
  }

  getClosestComponent<T extends CF2Component>(name: string): T {
    return this.element.closest<CF2Element>(`[data-page-element="${name}"]`)?.cf2_instance as T
  }

  getComponents<T extends CF2Component>(name: string): T[] {
    return Array.from(this.element.querySelectorAll<CF2Element>(`[data-page-element="${name}"]`))?.map(
      (c) => c.cf2_instance as T
    )
  }

  getComponentGroup<T extends CF2Component>(
    klass: CF2ComponentConstructor<T>,
    handlers?: GetHandlers<T>
  ): ComponentGroup<T> {
    const componentName = componentNameByClass[klass.name]
    const components = this.getComponents<T>(componentName)
    const group = createComponentGroup<T>(klass, components)
    if (handlers) {
      for (const handlerName in handlers) {
        group.on(handlerName, handlers[handlerName] as any)
      }
    }
    return group
  }

  setHandlers<T extends CF2Component>(klass: CF2ComponentConstructor<T>, handlers?: GetHandlers<T>): void {
    const componentName = componentNameByClass[klass.name]
    const components = this.getComponents<T>(componentName)
    for (const c of components) {
      for (const handlerName in handlers) {
        c.on(handlerName, handlers[handlerName] as any)
      }
    }
  }

  getAllComponents(): Array<CF2Component> {
    const componentList: Array<CF2Component> = []
    Array.from(this.element.querySelectorAll<CF2Element>('[data-page-element]'))?.forEach((c) => {
      c.cf2_instance && componentList.push(c.cf2_instance)
    })
    return componentList
  }

  on(eventName: string, eventHandler: CallableFunction): void {
    if (this.subscribers[eventName]) {
      this.subscribers[eventName].push(eventHandler)
    } else {
      console.warn(`Event ${eventName} not supported by ${this.constructor.name}`)
    }
  }
  // NOTE: Build components by firstly building inner elements, and then walking up tree.
  // As we need to move from the leaf nodes to parent nodes. It also accepts a list of old
  // components in which you can re-use components built from an old list.
  static hydrateTree(parentNode?: HTMLElement): void {
    const nodes = (parentNode ?? document).querySelectorAll<CF2Element>('[data-page-element]')
    nodes.forEach((node) => {
      const closestPageElement = $(node.parentNode).closest('[data-page-element]')[0]
      if (closestPageElement == parentNode || closestPageElement == null) {
        const klassName = node.getAttribute('data-page-element').replace('/', '')
        const ComponentBuilder = window[klassName]

        if (ComponentBuilder) {
          node.cf2_instance = new ComponentBuilder(node)
          node.getComponent = () => node.cf2_instance
          node.cf2_instance.initialize()
        }

        CF2Component.hydrateTree(node as CF2Element)

        if (ComponentBuilder) {
          node.cf2_instance.mount()
        }
      }
    })
  }
}

globalThis.CF2Component = CF2Component

export interface CF2Element extends HTMLElement {
  cf2_instance: CF2Component
  getComponent: () => CF2Component
}

globalThis.CF2HydrateTreeInitialized = false
window.addEventListener('DOMContentLoaded', () => {
  if (!globalThis.CF2HydrateTreeInitialized) {
    CF2Component.hydrateTree()
    queueMicrotask(() => {
      document.dispatchEvent(new CustomEvent('CF2:HydrateTreeInitialized'))
    })
  }
  globalThis.CF2HydrateTreeInitialized = true
})

export class ForloopDrop {
  protected i = 0
  public length: number
  public constructor(length: number) {
    this.length = length
  }
  public next(): void {
    this.i++
  }
  get index0(): number {
    return this.i
  }
  get index(): number {
    return this.i + 1
  }
  get first(): boolean {
    return this.i === 0
  }
  get last(): boolean {
    return this.i === this.length - 1
  }
  get rindex(): number {
    return this.length - this.i
  }
  get rindex0(): number {
    return this.length - this.i - 1
  }
}

globalThis.CF2ForloopDrop = ForloopDrop

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
globalThis.CF2Utils = globalThis.CF2Utils ?? {}
globalThis.CF2Utils.uuidv4 = uuidv4

export class CF2ComponentSingleton {
  private static _instance: CF2ComponentSingleton
  static getInstance(): CF2ComponentSingleton {
    if (this._instance) {
      return this._instance
    }
    this._instance = new this()
    return this._instance
  }
}

// globalThis.CF2ComponentSingleton = CF2ComponentSingleton
